import axios from './Axios'

const initialState = {
	loading: true,
	loading_recommend_form: true,
	loading_recommendations_tab: true,
	loading_beneficiaries_tab: true,
	recommendationForm: null,
	reasonForDevelopment: [],
	findingType: [],
	fundingFilters: null,
	fundingApplication: null,
	recommendationList: null,
	beneficiariesList: null,
	downloadedRecommendations: null,
	downloadedBeneficiaries: null,
	dropDownValues: null,
	existingRecommendationsForUser: null
}

const fundingRecommendationConstants = {
	MAKE_FUNDING_TYPE_MANDATORY: 'MAKE_FUNDING_TYPE_MANDATORY',

	CLEAN_UP_PREVIOUS_DATA: 'CLEAN_UP_PREVIOUS_DATA',

	GET_RECOMMENDATION: 'GET_RECOMMENDATION',
	GET_RECOMMENDATION_SUCCESS: 'GET_RECOMMENDATION_SUCCESS',
	GET_RECOMMENDATION_FAILURE: 'GET_RECOMMENDATION_FAILURE',

	GET_ALL_COMPANY_RECOMMENDATION: 'GET_ALL_COMPANY_RECOMMENDATION',
	GET_ALL_COMPANY_RECOMMENDATION_SUCCESS: 'GET_ALL_COMPANY_RECOMMENDATION_SUCCESS',
	GET_ALL_COMPANY_RECOMMENDATION_FAILURE: 'GET_ALL_COMPANY_RECOMMENDATION_FAILURE',

	GET_REASON_FOR_DEVELOPMENT: 'GET_REASON_FOR_DEVELOPMENT',
	GET_REASON_FOR_DEVELOPMENT_SUCCESS: 'GET_REASON_FOR_DEVELOPMENT_SUCCESS',
	GET_REASON_FOR_DEVELOPMENT_FAILURE: 'GET_REASON_FOR_DEVELOPMENT_FAILURE',

	GET_FUNDING_TYPE: 'GET_FUNDING_TYPE',
	GET_FUNDING_TYPE_SUCCESS: 'GET_FUNDING_TYPE_SUCCESS',
	GET_FUNDING_TYPE_FAILURE: 'GET_FUNDING_TYPE_FAILURE',

	GET_DEPARTMENTS: 'GET_DEPARTMENTS',
	GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
	GET_DEPARTMENTS_FAILURE: 'GET_DEPARTMENTS_FAILURE',

	SAVE_AS_DRAFT: 'SAVE_AS_DRAFT',
	SAVE_AS_DRAFT_SUCCESS: 'SAVE_AS_DRAFT_SUCCESS',
	SAVE_AS_DRAFT_FAILURE: 'SAVE_AS_DRAFT_FAILURE',

	SUBMIT_FOR_FUNDING: 'SUBMIT_FOR_FUNDING',
	SUBMIT_FOR_FUNDING_SUCCESS: 'SUBMIT_FOR_FUNDING_SUCCESS',
	SUBMIT_FOR_FUNDING_FAILURE: 'SUBMIT_FOR_FUNDING_FAILURE',

	GET_FUNDING_STATUS: 'GET_FUNDING_STATUS',
	GET_FUNDING_STATUS_SUCCESS: 'GET_FUNDING_STATUS_SUCCESS',
	GET_FUNDING_STATUS_FAILURE: 'GET_FUNDING_STATUS_FAILURE',

	GET_FUNDING_FILTERS: 'GET_FUNDING_FILTERS',
	GET_FUNDING_FILTERS_SUCCESS: 'GET_FUNDING_FILTERS_SUCCESS',
	GET_FUNDING_FILTERS_FAILURE: 'GET_FUNDING_FILTERS_FAILURE',

	FUNDING_RECOMMENDATIONS_SEARCH: 'FUNDING_RECOMMENDATIONS_SEARCH',
	FUNDING_RECOMMENDATIONS_SEARCH_SUCCESS: 'FUNDING_RECOMMENDATIONS_SEARCH_SUCCESS',
	FUNDING_RECOMMENDATIONS_SEARCH_FAILURE: 'FUNDING_RECOMMENDATIONS_SEARCH_FAILURE',

	GET_FUNDING_RECOMMENDATIONS: 'GET_FUNDING_RECOMMENDATIONS',
	GET_FUNDING_RECOMMENDATIONS_SUCCESS: 'GET_FUNDING_RECOMMENDATIONS_SUCCESS',
	GET_FUNDING_RECOMMENDATIONS_FAILURE: 'GET_FUNDING_RECOMMENDATIONS_FAILURE',

	DOWNLOAD_FUNDING_RECOMMENDATIONS: 'DOWNLOAD_FUNDING_RECOMMENDATIONS',
	DOWNLOAD_FUNDING_RECOMMENDATIONS_SUCCESS: 'DOWNLOAD_FUNDING_RECOMMENDATIONS_SUCCESS',
	DOWNLOAD_FUNDING_RECOMMENDATIONS_FAILURE: 'DOWNLOAD_FUNDING_RECOMMENDATIONS_FAILURE',

	CLEAR_DOWNLOADS: 'CLEAR_DOWNLOADS',

	GET_BENEFICIARIES: 'GET_BENEFICIARIES',
	GET_BENEFICIARIES_SUCCESS: 'GET_BENEFICIARIES_SUCCESS',
	GET_BENEFICIARIES_FAILURE: 'GET_BENEFICIARIES_FAILURE',

	DOWNLOAD_BENEFICIARIES: 'DOWNLOAD_BENEFICIARIES',
	DOWNLOAD_BENEFICIARIES_SUCCESS: 'DOWNLOAD_BENEFICIARIES_SUCCESS',
	DOWNLOAD_BENEFICIARIES_FAILURE: 'DOWNLOAD_BENEFICIARIES_FAILURE',

	GET_FUNDING_APPLICATION: 'GET_FUNDING_APPLICATION',
	GET_FUNDING_APPLICATION_SUCCESS: 'GET_FUNDING_APPLICATION_SUCCESS',
	GET_FUNDING_APPLICATION_FAILURE: 'GET_FUNDING_APPLICATION_FAILURE',
	CLEAR_FUNDING_APPLICATION: 'CLEAR_FUNDING_APPLICATION',

	CHANGE_FUNDING_STATUS: 'CHANGE_FUNDING_STATUS',
	CHANGE_BENEFICIARY_STATUS: 'CHANGE_BENEFICIARY_STATUS',

	SUBMIT_TO_SMME: 'SUBMIT_FOR_FUNDING',
	SUBMIT_TO_SMME_SUCCESS: 'SUBMIT_FOR_FUNDING_SUCCESS',
	SUBMIT_TO_SMME_FAILURE: 'SUBMIT_FOR_FUNDING_FAILURE',

	ADJUST_LOADING_STATE: 'ADJUST_LOADING_STATE',

	RECOMMENDED_OR_NOT: 'RECOMMENDED_OR_NOT',
	RECOMMENDED_OR_NOT_SUCCESS: 'RECOMMENDED_OR_NOT_SUCCESS',
	RECOMMENDED_OR_NOT_FAILURE: 'RECOMMENDED_OR_NOT_FAILURE',

	FOLLOWUP_AND_CANCEL_RECOMMENDATION: 'FOLLOWUP_AND_CANCEL_RECOMMENDATION',
	FOLLOWUP_AND_CANCEL_RECOMMENDATION_SUCCESS: 'FOLLOWUP_AND_CANCEL_RECOMMENDATION_SUCCESS',
	FOLLOWUP_AND_CANCEL_RECOMMENDATION_FAILURE: 'FOLLOWUP_AND_CANCEL_RECOMMENDATION_FAILURE',
}

export const fundingRecommendationActions = {
	// FOLLOWUP_AND_CANCEL_RECOMMENDATION
	followupAndCancelRecommendation: params => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.FOLLOWUP_AND_CANCEL_RECOMMENDATION,
			})

			return axios.get(`/main/follow-cancel-loan-recommend/`, params).then(
				response => {
					dispatch(fundingRecommendationActions.recommendedOrNotSuccess(response))
					return response
				},
				response => {
					dispatch(fundingRecommendationActions.recommendedOrNotFailure(response))
					throw response
				}
			)
		}
	},
	followupAndCancelRecommendationSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.FOLLOWUP_AND_CANCEL_RECOMMENDATION_SUCCESS,
				payload: response,
			})
		}
	},
	followupAndCancelRecommendationFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.FOLLOWUP_AND_CANCEL_RECOMMENDATION_FAILURE,
				payload: { error: response },
			})
		}
	},

	// SUBMIT_TO_SMME
	recommendedOrNot: id => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.RECOMMENDED_OR_NOT,
			})

			return axios.get(`/main/recommended_or_not/?sme_company_id=${id}`).then(
				response => {
					dispatch(fundingRecommendationActions.recommendedOrNotSuccess(response))
					return response
				},
				response => {
					dispatch(fundingRecommendationActions.recommendedOrNotFailure(response))
					throw response
				}
			)
		}
	},
	recommendedOrNotSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.RECOMMENDED_OR_NOT_SUCCESS,
				payload: response,
			})
		}
	},
	recommendedOrNotFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.RECOMMENDED_OR_NOT_FAILURE,
				payload: { error: response },
			})
		}
	},

	// MAKE_FUNDING_TYPE_MANDATORY
	makeFundingTypeMandatory: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.MAKE_FUNDING_TYPE_MANDATORY,
				payload: response,
			})
		}
	},

	// CLEAN_UP_PREVIOUS_DATA
	cleanUpPreviousData: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.CLEAN_UP_PREVIOUS_DATA,
			})
		}
	},

	// ADJUST_LOADING_STATE
	adjustLoadingState: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.ADJUST_LOADING_STATE,
			})
		}
	},

	// SUBMIT_TO_SMME
	submitToSmme: id => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SUBMIT_TO_SMME,
			})

			return axios.post(`/main/submit_recommendation_to_smme/`, id).then(
				response => {
					dispatch(fundingRecommendationActions.submitToSmmeSuccess(response))
					return response
				},
				response => {
					dispatch(fundingRecommendationActions.submitToSmmeFailure(response))
					throw response
				}
			)
		}
	},

	submitToSmmeSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SUBMIT_TO_SMME_SUCCESS,
				payload: response,
			})
		}
	},
	submitToSmmeFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SUBMIT_TO_SMME_FAILURE,
				payload: { error: response },
			})
		}
	},

	// CHANGE_BENEFICIARY_STATUS
	changeBeneficiaryStatus: params => {
		return () => {
			return axios
				.post('/main/loan_application_status_change/', params)
				.then(response => {
					return response
				})
				.catch(error => {
					return error
				})
		}
	},

	// RECOMMENDATION ACTIONS
	getDropdownData: params => {
		return () => {
			const { readOnly } = params
			return axios
				.get(`/main/recommendation-options/?readonly=${readOnly}`)
				.then(response => {
					return response
				})
				.catch(error => {
					return error
				})
		}
	},

	getAllCompanyRecommendations: params => {
		return dispatch => {
			const { sme_company_id } = params
			dispatch({
				type: fundingRecommendationConstants.GET_ALL_COMPANY_RECOMMENDATION,
			})

			return axios
				.get(`/main/get-all-company-recommendations/?sme_company_id=${sme_company_id}`)
				.then(res => {
					dispatch(fundingRecommendationActions.getAllCompanyRecommendationsSuccess(res))
					return res
				})
				.catch(err => {
					dispatch(fundingRecommendationActions.getAllCompanyRecommendationsFailure(err))
					return err
				})
		}
	},
	getAllCompanyRecommendationsSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_ALL_COMPANY_RECOMMENDATION_SUCCESS,
				payload: response.items,
				existing_recommendations_for_user: response.existing_recommendations_for_user
			})
		}
	},
	getAllCompanyRecommendationsFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_ALL_COMPANY_RECOMMENDATION_FAILURE,
				payload: { error: response.items }
			})
		}
	},

	fetchDraftedData: params => {
		const { rec_id, sme_company_id, form_id } = params
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_RECOMMENDATION,
			})

			return axios
				.get(`/main/get-recommendation/?rec_id=${rec_id}&sme_company_id=${sme_company_id}&form_id=${form_id ? form_id : null}`)
				.then(response => {
					dispatch(fundingRecommendationActions.getRecommendationSuccess(response))
					return response
				})
				.catch(error => {
					dispatch(fundingRecommendationActions.getRecommendationFailure(error))
					return error
				})
		}
	},
	getRecommendationSuccess: response => {
		return dispatch => {
			let data = { ...response.data, ...JSON.parse(response.data?.required_support) }
			dispatch({
				type: fundingRecommendationConstants.GET_RECOMMENDATION_SUCCESS,
				payload: data,
			})
		}
	},
	getRecommendationFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_RECOMMENDATION_FAILURE,
				payload: { error: response },
			})
		}
	},

	// DEPARTMENT ACTIONS
	getDropDowns: filter => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_DEPARTMENTS,
			})
			if (filter === 'recommendations') {
				return axios
					.get(`/main/department-status-list/`)
					.then(response => dispatch(fundingRecommendationActions.getDropDownsSuccess(response?.items)))
					.catch(response => dispatch(fundingRecommendationActions.getDropDownsFailure(response)))
			}
		}
	},
	getDropDownsSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_DEPARTMENTS_SUCCESS,
				payload: response,
			})
		}
	},
	getDropDownsFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_DEPARTMENTS_FAILURE,
				payload: { error: response },
			})
		}
	},

	// SAVE AS DRAFT ACTIONS
	saveAsDraft: payload => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SAVE_AS_DRAFT,
			})
			return axios.post(`/main/save-recommendation/`, payload).then(
				response => {
					dispatch(fundingRecommendationActions.saveAsDraftSuccess(response))
					return response
				},
				response => {
					dispatch(fundingRecommendationActions.saveAsDraftSuccess(response))
					throw response
				}
			)
		}
	},
	saveAsDraftSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SAVE_AS_DRAFT_SUCCESS,
				payload: response,
			})
		}
	},
	saveAsDraftFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SAVE_AS_DRAFT_FAILURE,
				payload: { error: response },
			})
		}
	},

	// SAVE AS DRAFT ACTIONS
	submitForFunding: payload => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SUBMIT_FOR_FUNDING,
			})
			return axios.post(`/main/save-recommendation/`, payload).then(
				response => {
					dispatch(fundingRecommendationActions.submitForFundingSuccess(response))
					return response
				},
				response => {
					dispatch(fundingRecommendationActions.submitForFundingFailure(response))
					throw response
				}
			)
		}
	},
	submitForFundingSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SUBMIT_FOR_FUNDING_SUCCESS,
				payload: response,
			})
		}
	},
	submitForFundingFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.SUBMIT_FOR_FUNDING_FAILURE,
				payload: { error: response },
			})
		}
	},

	// FUNDING_RECOMMENDATIONS_SEARCH ACTIONS
	fundingRecommendationsSearch: searchQuery => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.FUNDING_RECOMMENDATIONS_SEARCH,
			})

			return axios
				.get(`/main/filter-recommendation/${searchQuery}`)
				.then(response => dispatch(fundingRecommendationActions.fundingRecommendationsSearchSuccess(response?.items)))
				.catch(response => dispatch(fundingRecommendationActions.fundingRecommendationsSearchFailure(response)))
		}
	},
	fundingRecommendationsSearchSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.FUNDING_RECOMMENDATIONS_SEARCH_SUCCESS,
				payload: response,
			})
		}
	},
	fundingRecommendationsSearchFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.FUNDING_RECOMMENDATIONS_SEARCH_FAILURE,
				payload: { error: response },
			})
		}
	},

	getFundingRecommendations: params => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_FUNDING_RECOMMENDATIONS,
			})

			return axios
				.get(`/main/filter-recommendation/${params}`)
				.then(response => dispatch(fundingRecommendationActions.fundingRecommendationsSearchSuccess(response.items)))
				.catch(response => dispatch(fundingRecommendationActions.fundingRecommendationsSearchFailure(response)))
		}
	},
	getFundingRecommendationsSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_FUNDING_RECOMMENDATIONS_SUCCESS,
				payload: response,
			})
		}
	},
	getFundingRecommendationsFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_FUNDING_RECOMMENDATIONS_FAILURE,
				payload: { error: response },
			})
		}
	},
	// DOWNLOAD_FUNDING_RECOMMENDATIONS ACTIONS
	downloadFundingRecommendations: params => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.DOWNLOAD_FUNDING_RECOMMENDATIONS,
			})

			return axios
				.get(`/main/filter-recommendation/${params}`)
				.then(response => dispatch(fundingRecommendationActions.downloadFundingRecommendationsSuccess(response.items.items)))
				.catch(response => dispatch(fundingRecommendationActions.downloadFundingRecommendationsFailure(response)))
		}
	},
	downloadFundingRecommendationsSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.DOWNLOAD_FUNDING_RECOMMENDATIONS_SUCCESS,
				payload: response,
			})
		}
	},
	downloadFundingRecommendationsFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.DOWNLOAD_FUNDING_RECOMMENDATIONS_FAILURE,
				payload: { error: response },
			})
		}
	},

	// CLEAR DOWNLOADS ACTIONS
	clearDownloads: searchQuery => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.CLEAR_DOWNLOADS,
			})
		}
	},

	// DOWNLOAD_BENEFICIARIES ACTIONS
	downloadFundingBeneficiaries: payload => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.DOWNLOAD_BENEFICIARIES,
			})

			return axios
				.post(`/main/get_loan_beneficiaries`, payload)
				.then(response => dispatch(fundingRecommendationActions.downloadBeneficiariesSuccess(response.items)))
				.catch(response => dispatch(fundingRecommendationActions.downloadBeneficiariesFailure(response)))
		}
	},
	downloadBeneficiariesSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.DOWNLOAD_BENEFICIARIES_SUCCESS,
				payload: response,
			})
		}
	},
	downloadBeneficiariesFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.DOWNLOAD_BENEFICIARIES_FAILURE,
				payload: { error: response },
			})
		}
	},

	// GET_BENEFICIARIES ACTIONS
	getBeneficiaries: payload => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_BENEFICIARIES,
			})

			return axios
				.post(`/main/get_loan_beneficiaries`, payload)
				.then(response => dispatch(fundingRecommendationActions.getBeneficiariesSuccess(response)))
				.catch(response => dispatch(fundingRecommendationActions.getBeneficiariesFailure(response)))
		}
	},
	getBeneficiariesSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_BENEFICIARIES_SUCCESS,
				payload: response,
			})
		}
	},
	getBeneficiariesFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_BENEFICIARIES_FAILURE,
				payload: { error: response },
			})
		}
	},

	// GET_FUNDING_FILTERS INITIAL VALUES
	getFundingFilters: searchQuery => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_BENEFICIARIES,
			})

			dispatch(fundingRecommendationActions.getFundingFiltersSuccess(initialState.fundingFilters))
		}
	},
	getFundingFiltersSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_FUNDING_FILTERS_SUCCESS,
				payload: response,
			})
		}
	},

	// GET_FUNDING_APPLICATION ACTIONS
	getFundingApplication: ({ sme_company_id, recommend_user_id }) => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_FUNDING_APPLICATION,
			})

			return axios
				.get(`/main/funding-application-status/?sme_company_id=${sme_company_id}&recommend_user_id=${recommend_user_id ? recommend_user_id : null}`)
				.then(response => dispatch(fundingRecommendationActions.getFundingApplicationSuccess(response?.items)))
				.catch(response => dispatch(fundingRecommendationActions.getFundingApplicationFailure(response)))
		}
	},
	getFundingApplicationSuccess: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_FUNDING_APPLICATION_SUCCESS,
				payload: response,
			})
		}
	},
	getFundingApplicationFailure: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.GET_FUNDING_APPLICATION_FAILURE,
				payload: { error: response },
			})
		}
	},
	clearFundingApplication: response => {
		return dispatch => {
			dispatch({
				type: fundingRecommendationConstants.CLEAR_FUNDING_APPLICATION,
			})
		}
	},
}

export function fundingRecommendationReducer(state = initialState, action) {
	switch (action.type) {
		// MAKE_FUNDING_TYPE_MANDATORY
		case fundingRecommendationConstants.MAKE_FUNDING_TYPE_MANDATORY:
			if (action.payload) {
				return {
					...state,
					recommendationForm: {
						...action.payload,
						financial: false,
					},
				}
			} else {
				return {
					...state,
				}
			}
		// CLEAN_UP_PREVIOUS_DATA
		case fundingRecommendationConstants.CLEAN_UP_PREVIOUS_DATA:
			return {
				...state,
				loading: true,
				loading_recommend_form: true,
				loading_recommendations_tab: true,
				loading_beneficiaries_tab: true,
				recommendationForm: null,
				reasonForDevelopment: [],
				findingType: [],
				fundingFilters: null,
				fundingApplication: null,
				recommendationList: null,
				beneficiariesList: null,
				downloadedRecommendations: null,
				downloadedBeneficiaries: null,
				dropDownValues: null,
			}
		// ADJUST_LOADING_STATE
		case fundingRecommendationConstants.ADJUST_LOADING_STATE:
			return {
				...state,
				loading: true,
				loading_recommend_form: true,
			}
		// SUBMIT_TO_SMME
		case fundingRecommendationConstants.SUBMIT_TO_SMME:
			return {
				...state,
				loading: true,
			}
		case fundingRecommendationConstants.SUBMIT_TO_SMME_SUCCESS:
			return {
				...state,
				loading: false,
			}
		case fundingRecommendationConstants.SUBMIT_TO_SMME_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}

		// GET_ALL_COMPANY_RECOMMENDATION
		case fundingRecommendationConstants.GET_ALL_COMPANY_RECOMMENDATION:
			return {
				...state,
				loading_recommend_form: true,
			}
		case fundingRecommendationConstants.GET_ALL_COMPANY_RECOMMENDATION_SUCCESS:
			return {
				...state,
				loading_recommend_form: false,
				recommendationsList: action.payload,
				existingRecommendationsForUser: action.existing_recommendations_for_user
			}
		case fundingRecommendationConstants.GET_ALL_COMPANY_RECOMMENDATION_FAILURE:
			return {
				...state,
				loading_recommend_form: false,
				error: action.payload.error,
			}

		// GET_RECOMMENDATION
		case fundingRecommendationConstants.GET_RECOMMENDATION:
			return {
				...state,
				loading_recommend_form: true,
			}
		case fundingRecommendationConstants.GET_RECOMMENDATION_SUCCESS:
			return {
				...state,
				loading_recommend_form: false,
				recommendationForm: action.payload,
			}
		case fundingRecommendationConstants.GET_RECOMMENDATION_FAILURE:
			return {
				...state,
				loading_recommend_form: false,
				error: action.payload.error,
			}

		// GET_REASON_FOR_DEVELOPMENT
		case fundingRecommendationConstants.GET_REASON_FOR_DEVELOPMENT:
			return {
				...state,
			}
		case fundingRecommendationConstants.GET_REASON_FOR_DEVELOPMENT_SUCCESS:
			return {
				...state,
				reasonForDevelopment: action.payload,
			}
		case fundingRecommendationConstants.GET_REASON_FOR_DEVELOPMENT_FAILURE:
			return {
				...state,
				error: action.payload.error,
			}

		// GET_FUNDING_TYPE
		case fundingRecommendationConstants.GET_FUNDING_TYPE:
			return {
				...state,
			}
		case fundingRecommendationConstants.GET_FUNDING_TYPE_SUCCESS:
			return {
				...state,
				findingType: action.payload,
			}
		case fundingRecommendationConstants.GET_FUNDING_TYPE_FAILURE:
			return {
				...state,
				error: action.payload.error,
			}

		// GET_DEPARTMENTS
		case fundingRecommendationConstants.GET_DEPARTMENTS:
			return {
				...state,
			}
		case fundingRecommendationConstants.GET_DEPARTMENTS_SUCCESS:
			return {
				...state,
				dropDownValues: action.payload,
			}
		case fundingRecommendationConstants.GET_DEPARTMENTS_FAILURE:
			return {
				...state,
				error: action.payload.error,
			}

		// SAVE_AS_DRAFT
		case fundingRecommendationConstants.SAVE_AS_DRAFT:
			return {
				...state,
				loading_recommend_form: true,
			}
		case fundingRecommendationConstants.SAVE_AS_DRAFT_SUCCESS:
			return {
				...state,
				loading_recommend_form: false,
			}
		case fundingRecommendationConstants.SAVE_AS_DRAFT_FAILURE:
			return {
				...state,
				loading_recommend_form: false,
				error: action.payload.error,
			}

		// SUBMIT_FOR_FUNDING
		case fundingRecommendationConstants.SUBMIT_FOR_FUNDING:
			return {
				...state,
				loading_recommend_form: true,
			}
		case fundingRecommendationConstants.SUBMIT_FOR_FUNDING_SUCCESS:
			return {
				...state,
				loading_recommend_form: false,
			}
		case fundingRecommendationConstants.SUBMIT_FOR_FUNDING_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading_recommend_form: false,
			}

		// GET_FUNDING_STATUS
		case fundingRecommendationConstants.GET_FUNDING_STATUS:
			return {
				...state,
			}
		case fundingRecommendationConstants.GET_FUNDING_STATUS_SUCCESS:
			return {
				...state,
				fundingStatus: action.payload,
			}
		case fundingRecommendationConstants.GET_FUNDING_STATUS_FAILURE:
			return {
				...state,
				error: action.payload.error,
			}

		// FUNDING_RECOMMENDATIONS_SEARCH
		case fundingRecommendationConstants.FUNDING_RECOMMENDATIONS_SEARCH:
			return {
				...state,
				loading: true,
				loading_recommendations_tab: true,
			}
		case fundingRecommendationConstants.FUNDING_RECOMMENDATIONS_SEARCH_SUCCESS:
			return {
				...state,
				recommendationList: action.payload,
				loading: false,
				loading_recommendations_tab: false,
			}
		case fundingRecommendationConstants.FUNDING_RECOMMENDATIONS_SEARCH_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
				loading_recommendations_tab: false,
			}

		// GET_FUNDING_RECOMMENDATIONS
		case fundingRecommendationConstants.GET_FUNDING_RECOMMENDATIONS:
			return {
				...state,
				loading_recommendations_tab: true,
			}
		case fundingRecommendationConstants.GET_FUNDING_RECOMMENDATIONS_SUCCESS:
			return {
				...state,
				recommendationList: action.payload,
				loading_recommendations_tab: false,
			}
		case fundingRecommendationConstants.GET_FUNDING_RECOMMENDATIONS_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading_recommendations_tab: false,
			}

		// GET_FUNDING_RECOMMENDATIONS
		case fundingRecommendationConstants.GET_BENEFICIARIES:
			return {
				...state,
				loading: true,
				loading_beneficiaries_tab: true,
			}
		case fundingRecommendationConstants.GET_BENEFICIARIES_SUCCESS:
			return {
				...state,
				beneficiariesList: action.payload,
				loading: false,
				loading_beneficiaries_tab: false,
			}
		case fundingRecommendationConstants.GET_BENEFICIARIES_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
				loading_beneficiaries_tab: false,
			}

		// GET_FUNDING_FILTERS
		case fundingRecommendationConstants.GET_FUNDING_FILTERS:
			return {
				...state,
			}
		case fundingRecommendationConstants.GET_FUNDING_FILTERS_SUCCESS:
			return {
				...state,
				fundingFilters: action.payload,
			}

		// GET_FUNDING_RECOMMENDATIONS
		case fundingRecommendationConstants.GET_FUNDING_APPLICATION:
			return {
				...state,
				loading: true,
			}
		case fundingRecommendationConstants.GET_FUNDING_APPLICATION_SUCCESS:
			return {
				...state,
				fundingApplication: action.payload,
				loading: false,
			}
		case fundingRecommendationConstants.GET_FUNDING_APPLICATION_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}
		case fundingRecommendationConstants.CLEAR_FUNDING_APPLICATION:
			return {
				...state,
				fundingApplication: null,
			}

		// DOWNLOAD_FUNDING_RECOMMENDATIONS
		case fundingRecommendationConstants.DOWNLOAD_FUNDING_RECOMMENDATIONS:
			return {
				...state,
				loading_recommendations_tab: true,
			}
		case fundingRecommendationConstants.DOWNLOAD_FUNDING_RECOMMENDATIONS_SUCCESS:
			return {
				...state,
				loading_recommendations_tab: false,
				downloadedRecommendations: action.payload,
			}
		case fundingRecommendationConstants.DOWNLOAD_FUNDING_RECOMMENDATIONS_FAILURE:
			return {
				...state,
				loading_recommendations_tab: false,
				error: action.payload.error,
			}

		// CLEAR_DOWNLOADS
		case fundingRecommendationConstants.CLEAR_DOWNLOADS:
			return {
				...state,
				downloadedRecommendations: null,
				downloadedBeneficiaries: null,
			}

		// DOWNLOAD_BENEFICIARIES
		case fundingRecommendationConstants.DOWNLOAD_BENEFICIARIES:
			return {
				...state,
				loading_beneficiaries_tab: true,
			}
		case fundingRecommendationConstants.DOWNLOAD_BENEFICIARIES_SUCCESS:
			return {
				...state,
				loading_beneficiaries_tab: false,
				downloadedBeneficiaries: action.payload,
			}
		case fundingRecommendationConstants.DOWNLOAD_BENEFICIARIES_FAILURE:
			return {
				...state,
				loading_beneficiaries_tab: false,
				error: action.payload.error,
			}

		default:
			return state
	}
}
