// PLATFORM FEATURES
export const BUSINESS_PROFILE = 'business-profile'
export const CONFIGURATION = 'configuration'
export const CSD = 'csd'
export const DEV_PLANS = 'dev-plans'
export const DIAGNOSTICS = 'diagnostics'
export const ECONOMIC_SECTORS = 'economic-sectors'
export const FUNDING_REQUESTS = 'funding-requests'
export const GROWTH_PLANS = 'growth-plans'
export const INDUSTRY_GROUPS = 'industry-groups'
export const INTERVIEWS = 'interviews'
export const LOAN_MODULE = 'loan-module'
export const MANUAL_GRADING = 'manual-grading'
export const OPPORTUNITIES = 'opportunities'
export const PROJECTS = 'projects'
export const REFERENCE_REVIEWS = 'reference-reviews'
export const SHE_QUESTIONNAIRE = 'she-questionnaire'
export const ENTERPRISE_INDUSTRY_CLASSIFICATION='enterprise_industry_classification'
export const ENVIRONMENT_CLASS = 'environment_class'
export const ZEVOLI_CLASS = 'zevoli'
export const CSD_URL='https://secure.csd.gov.za/Home/RegistrationProcess'
export const SME_TRACKING = 'sme-tracking'
//GRADEs
export const PLATINUM = 'platinum'
export const PLATINUM_CODE = 1004
export const PLATINUM_PLUS_CODE = 1005
export const GRADES = { UNGRADED: 'Ungraded', BLUE: 'Blue', BRONZE: 'Bronze', SILVER: 'Silver', GOLD: 'Gold', PLATINUM: 'platinum', PLATINUM_PLUS: 'Platinum Plus' }
export const CODES = { UNGRADED_CODE: 99, BLUE_CODE: 1000, BRONZE_CODE: 1001, SILVER_CODE: 1002, GOLD_CODE: 1003, PLATINUM_CODE: 1004, PLATINUM_PLUS_CODE: 1005 }
export const PERMISSIONS = {
	SME_COMPANY_GROWTH_PLANS_C: 'sme-company-growth-plans:c',
}
export const COLOR = {
	UNGRADED_COLOR: '#808080',
	ESD_UNGRADED_COLOR: '#d7e6f3',
	BLUE_COLOR: '#5B99CE',
	BRONZE_COLOR: '#CD7F32',
	SILVER_COLOR: '#8FA1AC',
	GOLD_COLOR: '#DAA236',
	PLATINUM_COLOR: '#043962',
	PLATINUM_PLUS_COLOR: '#19967D',
	LAYOUT_BACKGROUND_COLOR: '#F6F7FB',
}

export const STATUSES = {
	NOT_STARTED: 'not-started',
	NOT_VERIFIED: 'not verified',
	PENDING: 'pending',
	RECOMMENDED: 'recommended',
	REJECTED: 'rejected',
	CHANGE_REQUESTED: 'change requested',
	DRAFT: 'draft',
	SUBMITTED: 'submitted',
	VERIFIED: '(Verified)',
	UNVERIFIED: '(UnVerified)'
}
// INDUSTRY CLASSIFICATION

export const INDUSTRY_CLASSIFICATION = {
	EME_ID: 1,
	QSE_ID: 2,
	EME_CATEGORY_LIMIT: 1,
	QSE_CATEGORY_LIMIT: 3,
	EME_COMMODITIES_LIMIT: 3,
	QSE_COMMODITIES_LIMIT: 9,
}

// MISCELLANEOUS
export const SME_COMPANY_ID = 'sme_company_id'
export const ACCREDITATION = 'Accreditation'
export const INDUSTRY = 'Industry'

// DATE FORMAT
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

// REGULAR EXPRESSIONS
export const ESD_DASHBOARD_FORMAT_NUMBER = '/B(?=(d{3})+(?!d))/g'
export const HTTP = 'http://'
export const URL_VALIDATION =
	'^(https?:\\/\\/)?' + // protocol
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
	'(\\#[-a-z\\d_]*)?$' // fragment locator
	