import axios from './Axios';

export const createCrud = (baseUrl, modelSingular, modelPlural, initialState = {}, verbs = ['get', 'list', 'post', 'patch', 'put', 'delete']) => {

    if (!baseUrl.endsWith('/'))
        baseUrl += '/';

    const MODEL = modelSingular.toUpperCase();
    let constants = {};
    verbs.map((item) => {
        constants[(`${item}_${MODEL}`).toUpperCase()] = (`${item}_${MODEL}`).toUpperCase();
        constants[(`${item}_${MODEL}_SUCCESS`).toUpperCase()] = (`${item}_${MODEL}`).toUpperCase();
        constants[(`${item}_${MODEL}_FAILURE`).toUpperCase()] = (`${item}_${MODEL}`).toUpperCase();
        return item;
    });

    let actions = {};

    if ( verbs.indexOf("get") > -1 ) {
        actions[`get${modelSingular}Success`] = (response) => {
            return (dispatch) => {
                dispatch({
                    type: constants[`GET_${MODEL}_SUCCESS`],
                    payload: {
                        item: response,
                        loading: false
                    }
                });
            };
        };

        actions[`get${modelSingular}Failure`] = (response) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`GET_${MODEL}_FAILURE`],
                    payload: {
                        items: [],
                        error: response,
                        loading: false
                    }
                });

            };
        };

        actions[`get${modelSingular}`] = (id) => {
            // get a single entity by ID
            return (dispatch) => {

                dispatch({
                    type: constants[`GET_${MODEL}`],
                    payload: {
                        loading: true
                    }
                });

                return axios.get(baseUrl + (id? id + '/': '') )
                    .then(
                        (response) => { dispatch(actions[`get${modelSingular}Success`](response)); return response; },
                        (response) => { dispatch(actions[`get${modelSingular}Failure`](response)); throw response; }
                    );
            };
        }
    }

    if ( verbs.indexOf('list') > -1 ) {

        actions[`get${modelPlural}Success`] = (response) => {
            return (dispatch) => {
                dispatch({
                    type: constants[`LIST_${MODEL}_SUCCESS`],
                    payload: {
                        items: response.items,
                        loading: false
                    }
                });
            };
        };

        actions[`get${modelPlural}Failure`] = (response) => {
            return dispatch => {

                dispatch({
                    type: constants[`LIST_${MODEL}_FAILURE`],
                    payload: {
                        items: [],
                        error: response,
                        loading: false
                    }
                });

            };
        };

        const fn = (data, timeout = undefined) => {
            // data is used as params to filter the response
            return (dispatch) => {
                dispatch({
                    type: constants[`LIST_${MODEL}`],
                    payload: {
                        loading: true
                    }
                });

                let reqData
                if(timeout === undefined) {
                    reqData = { params: data }
                } else {
                    reqData = { params: data, timeout }
                }
                
                return axios.get(baseUrl, reqData)
                    .then(
                        (response) => { dispatch(actions[`get${modelPlural}Success`](response)); return response; },
                        (response) => { dispatch(actions[`get${modelPlural}Failure`](response)); throw response; });
            };
        };
        actions[`get${modelPlural}`] = fn;
        actions[`list${modelPlural}`] = fn;

    }

    if ( verbs.indexOf('post') > -1 ) {

        /* post */
        actions[`post${modelSingular}Success`] = (response) => {
            return (dispatch) => {
                dispatch({
                    type: constants[`POST_${MODEL}_SUCCESS`],
                    payload: {
                        loading: false
                    }
                });
            };
        };

        actions[`post${modelSingular}Failure`] = (response) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`POST_${MODEL}_FAILURE`],
                    payload: {
                        loading: false,
                        errors: response
                    }
                });

            };
        };

        actions[`post${modelSingular}`] = (data) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`POST_${MODEL}`],
                    payload: {
                        loading: true,
                    }
                });

                return axios.post(baseUrl, data)
                    .then(
                        (response) => { dispatch(actions[`post${modelSingular}Success`](response)); return response; },
                        (response) => { dispatch(actions[`post${modelSingular}Failure`](response)); throw response; });

            };
        };

    }

    if ( verbs.indexOf('patch') > -1 ) {

        actions[`patch${modelSingular}`] = (data) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`PATCH_${MODEL}`],
                    payload: {
                        loading: true
                    },
                });

                return axios.patch(baseUrl + data.id, data)
                    .then(
                        (response) => { dispatch(actions[`patch${modelSingular}Success`](response)); return response; },
                        (response) => { dispatch(actions[`patch${modelSingular}Failure`](response)); throw response; }
                    );

            };
        };

        actions[`patch${modelSingular}Success`] = (response) => {
            return (dispatch) => {
                dispatch({
                    type: constants[`PATCH_${MODEL}_SUCCESS`],
                    payload: {
                        loading: false,
                        response: response
                    }
                });
            };
        };

        actions[`patch${modelSingular}Failure`] = (response) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`PATCH_${MODEL}_FAILURE`],
                    payload: {
                        loading: false,
                        errors: response
                    }
                });

            };
        };
    }


    if ( verbs.indexOf('put') > -1 ) {

        actions[`put${modelSingular}`] = (data) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`PATCH_${MODEL}`],
                    payload: {
                        loading: true
                    },
                });

                return axios.put(baseUrl + data.id, data)
                    .then(
                        (response) => { dispatch(actions[`put${modelSingular}Success`](response)); return response; },
                        (response) => { dispatch(actions[`put${modelSingular}Failure`](response)); throw response; }
                    );

            };
        };

        actions[`put${modelSingular}Success`] = (response) => {
            return (dispatch) => {
                dispatch({
                    type: constants[`PUT_${MODEL}_SUCCESS`],
                    payload: {
                        loading: false,
                        response: response
                    }
                });
            };
        };

        actions[`put${modelSingular}Failure`] = (response) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`PUT_${MODEL}_FAILURE`],
                    payload: {
                        loading: false,
                        errors: response
                    }
                });

            };
        };
    }
    

    if ( verbs.indexOf('delete') > -1 ) {

        actions[`delete${modelSingular}`] = (id) => {
            return (dispatch) => {

                dispatch({
                    type: constants[`DELETE_${MODEL}`],
                    payload: { loading: true },
                });

                return axios.delete(baseUrl + id)
                    .then(
                        (response) => { dispatch(actions[`delete${modelSingular}Success`](response)); return response; },
                        (response) => { dispatch(actions[`delete${modelSingular}Failure`](response)); throw response; }
                        );

            };
        };

        actions[`delete${modelSingular}Success`] = (response) => {
            return (dispatch) => {
                dispatch({
                    type: constants[`DELETE_${MODEL}_SUCCESS`],
                    payload: {
                        loading: false,
                        response: response
                    }
                });
            };
        };

        actions[`delete${modelSingular}Failure`] = (response) => {
            return (dispatch) => {
                dispatch({
                    type: constants[`DELETE_${MODEL}_FAILURE`],
                    payload: {
                        loading: false,
                        errors: response
                    }
                });
            };
        };
    }

    const reducer = (state = initialState, action) => {
        if ( constants[action.type] ) {
            return {
                ...state,
                ...action.payload
            };
        }
        return state;
    };

    return {
        constants,
        actions,
        reducer
    }
};

