import axios from './Axios'

const initialState = {
	loading: false,
	loanApplicationForm: null,
	loanApplicationFormDropdown: null,
	loanApplicationFormPost: null,
	loanApplicationEsdFormPost: null,
	loanApplicationFsFormApprove: null,
	processedApplicationForm: null,
	submitFailed: null,
}

const loanApplicationConstants = {
	GET_LOAN_APPLICATION: 'GET_LOAN_APPLICATION',
	GET_LOAN_APPLICATION_SUCCESS: 'GET_LOAN_APPLICATION_SUCCESS',
	GET_LOAN_APPLICATION_FAILURE: 'GET_LOAN_APPLICATION_FAILURE',

	GET_LOAN_APPLICATION_DROPDOWN: 'GET_LOAN_APPLICATION_DROPDOWN',
	GET_LOAN_APPLICATION_DROPDOWN_SUCCESS: 'GET_LOAN_APPLICATION_DROPDOWN_SUCCESS',
	GET_LOAN_APPLICATION_DROPDOWN_FAILURE: 'GET_LOAN_APPLICATION_DROPDOWN_FAILURE',

	POST_LOAN_APPLICATION: 'POST_LOAN_APPLICATION',
	POST_LOAN_APPLICATION_SUCCESS: 'POST_LOAN_APPLICATION_SUCCESS',
	POST_LOAN_APPLICATION_FAILURE: 'POST_LOAN_APPLICATION_FAILURE',

	ESD_SUBMIT_LOAN_APPLICATION: 'ESD_SUBMIT_LOAN_APPLICATION',
	ESD_SUBMIT_LOAN_APPLICATION_SUCCESS: 'ESD_SUBMIT_LOAN_APPLICATION_SUCCESS',
	ESD_SUBMIT_LOAN_APPLICATION_FAILURE: 'ESD_SUBMIT_LOAN_APPLICATION_FAILURE',

	FS_APPROVE_LOAN_APPLICATION: 'FS_APPROVE_LOAN_APPLICATION',
	FS_APPROVE_LOAN_APPLICATION_SUCCESS: 'FS_APPROVE_LOAN_APPLICATION_SUCCESS',
	FS_APPROVE_LOAN_APPLICATION_FAILURE: 'FS_APPROVE_LOAN_APPLICATION_FAILURE',

	STORE_PROCESSED_FORM_DATA: 'STORE_PROCESSED_FORM_DATA',
}

export const loanApplicationActions = {
	storeProcessedFormData: data => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.STORE_PROCESSED_FORM_DATA,
				payload: data,
			})
		}
	},

	getSmeLoanApplication: company_id => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.GET_LOAN_APPLICATION,
			})
			return axios.get(`/main/loan_application/?sme_company_id=${company_id}`).then(
				response => {
					dispatch(loanApplicationActions.getSmeLoanApplicationSuccess(response))
					return response
				},
				response => {
					dispatch(loanApplicationActions.getSmeLoanApplicationFailure(response))
					throw response
				}
			)
		}
	},

	getSmeLoanApplicationSuccess: data => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.GET_LOAN_APPLICATION_SUCCESS,
				payload: data,
			})
		}
	},

	getSmeLoanApplicationFailure: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.GET_LOAN_APPLICATION_FAILURE,
				payload: { error: response },
			})
		}
	},

	getSmeLoanApplicationDropdown: company_id => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.GET_LOAN_APPLICATION_DROPDOWN,
			})

			// setTimeout(() => {
			// 	fetch('/sample-data/loan-application-form-dropdown-data.json')
			// 		.then(response => response.json())
			// 		.then(response => dispatch(loanApplicationActions.getSmeLoanApplicationDropdownSuccess(response)))
			// 		.catch(response => dispatch(loanApplicationActions.getSmeLoanApplicationDropdownFailure(response)))
			// }, 1000)

			axios
				.get(`/main/get_loan_application_options`)
				.then(response => dispatch(loanApplicationActions.getSmeLoanApplicationDropdownSuccess(response)))
				.catch(response => dispatch(loanApplicationActions.getSmeLoanApplicationDropdownFailure(response)))
		}
	},

	getSmeLoanApplicationDropdownSuccess: data => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.GET_LOAN_APPLICATION_DROPDOWN_SUCCESS,
				payload: data,
			})
		}
	},

	getSmeLoanApplicationDropdownFailure: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.GET_LOAN_APPLICATION_DROPDOWN_FAILURE,
				payload: { error: response },
			})
		}
	},

	postSmeLoanApplication: ({ payload }) => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.POST_LOAN_APPLICATION,
			})

			return axios.post(`/main/loan_application`, payload).then(
				response => {
					dispatch(loanApplicationActions.postSmeLoanApplicationSuccess(response))
					return response
				},
				response => {
					dispatch(loanApplicationActions.postSmeLoanApplicationFailure(response))
					throw response
				}
			)
		}
	},

	postSmeLoanApplicationSuccess: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.POST_LOAN_APPLICATION_SUCCESS,
				payload: response,
			})
		}
	},
	postSmeLoanApplicationFailure: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.POST_LOAN_APPLICATION_FAILURE,
				payload: { error: response },
			})
		}
	},

	submitEsdLoanApplication: ({ payload }) => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.ESD_SUBMIT_LOAN_APPLICATION,
			})

			return axios.post(`/main/loan_application_status_change`, payload).then(
				response => {
					dispatch(loanApplicationActions.submitEsdLoanApplicationSuccess(response))
					return response
				},
				response => {
					dispatch(loanApplicationActions.submitEsdLoanApplicationFailure(response))
					throw response
				}
			)
		}
	},

	submitEsdLoanApplicationSuccess: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.ESD_SUBMIT_LOAN_APPLICATION_SUCCESS,
				payload: response,
			})
		}
	},
	submitEsdLoanApplicationFailure: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.ESD_SUBMIT_LOAN_APPLICATION_FAILURE,
				payload: { error: response },
			})
		}
	},

	approveFsLoanApplication: ({ payload }) => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.FS_APPROVE_LOAN_APPLICATION,
			})

			return axios.post(`/main/loan_application_status_change`, payload).then(
				response => {
					dispatch(loanApplicationActions.approveFsLoanApplicationSuccess(response))
					return response
				},
				response => {
					dispatch(loanApplicationActions.approveFsLoanApplicationFailure(response))
					throw response
				}
			)
		}
	},

	approveFsLoanApplicationSuccess: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.FS_APPROVE_LOAN_APPLICATION_SUCCESS,
				payload: response,
			})
		}
	},
	approveFsLoanApplicationFailure: response => {
		return dispatch => {
			dispatch({
				type: loanApplicationConstants.FS_APPROVE_LOAN_APPLICATION_FAILURE,
				payload: { error: response },
			})
		}
	},
}

export function loanApplicationReducer(state = initialState, action) {
	switch (action.type) {
		// GET_LOAN_APPLICATION
		case loanApplicationConstants.GET_LOAN_APPLICATION:
			return {
				...state,
				loading: true,
			}

		case loanApplicationConstants.GET_LOAN_APPLICATION_SUCCESS:
			return {
				...state,
				loanApplicationForm: action.payload,
				loading: false,
			}
		case loanApplicationConstants.GET_LOAN_APPLICATION_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}
		// DROPDOWN_LOAN_APPLICATION
		case loanApplicationConstants.GET_LOAN_APPLICATION_DROPDOWN:
			return {
				...state,
				loading: true,
			}
		case loanApplicationConstants.GET_LOAN_APPLICATION_DROPDOWN_SUCCESS:
			return {
				...state,
				loanApplicationFormDropdown: action.payload,
				loading: false,
			}
		case loanApplicationConstants.GET_LOAN_APPLICATION_DROPDOWN_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}

		// POST_LOAN_APPLICATION
		case loanApplicationConstants.POST_LOAN_APPLICATION:
			return {
				...state,
				loading: true,
			}

		case loanApplicationConstants.POST_LOAN_APPLICATION_SUCCESS:
			return {
				...state,
				loanApplicationFormPost: action.payload,
				loading: false,
			}
		case loanApplicationConstants.POST_LOAN_APPLICATION_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}

		//Esd Submit to Funding

		case loanApplicationConstants.ESD_SUBMIT_LOAN_APPLICATION:
			return {
				...state,
				loading: true,
			}

		case loanApplicationConstants.ESD_SUBMIT_LOAN_APPLICATION_SUCCESS:
			return {
				...state,
				loanApplicationEsdFormPost: action.payload,
				loading: false,
			}
		case loanApplicationConstants.ESD_SUBMIT_LOAN_APPLICATION_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}

		//Fs Approve Application
		case loanApplicationConstants.FS_APPROVE_LOAN_APPLICATION:
			return {
				...state,
				loading: true,
			}

		case loanApplicationConstants.FS_APPROVE_LOAN_APPLICATION_SUCCESS:
			return {
				...state,
				loanApplicationFsFormApprove: action.payload,
				loading: false,
			}
		case loanApplicationConstants.FS_APPROVE_LOAN_APPLICATION_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}

		// STORE_PROCESSED_FORM_DATA
		case loanApplicationConstants.STORE_PROCESSED_FORM_DATA:
			return {
				...state,
				processedApplicationForm: action.payload,
				loading: false,
			}

		
		default:
			return state
	}
}
